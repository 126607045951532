import { isMobile } from "react-device-detect";
export const Premium = () => {
  let stat = "";
  if (isMobile) {
    stat = "test-mobile";
  } else {
    stat = "test";
  }
  return (
    <div>
      <div class="container">
        <div class="row">
          <div  id={stat} class="our-blog section">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="section-heading" style={{ textAlign: "left" }}>
                    <h2>
                      Check Out What Is <em>Premium Services</em>
                    </h2>
                    <p>
                      Service area :
                      <div class="row">
                        <div class="col-lg-2">
                          <ul>
                            <li>* Denpasar</li>
                            <li>* Badung</li>
                            <li>* Ubud</li>
                          </ul>
                        </div>
                        <div class="col-lg-8">
                          <ul>
                            <li>* Tabanan</li>
                            <li>* Gianyar</li>
                            <li>
                              <i>
                                (Ubud Harga Premium sudah termasuk biaya
                                transportasi.)
                              </i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="top-dec">
                    <img src="assets/images/blog-dec.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-lg-6 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="left-image">
                    <a href="#">
                      <img
                        src="assets/images/979.jpg"
                        alt="Workspace Desktop"
                      />
                    </a>
                    <div class="info">
                      <div class="inner-content">
                        <a href="#">
                          <h4>Home Spa Massage</h4>
                        </a>
                        <p>
                          Layanan Spa yang dilakukan di rumah pelanggan untuk
                          membawa suasana spa langsung ke dalam rumah.
                        </p>
                        <br />
                        Pelayanan :
                        <ul>
                          <li>#Merendamkan kaki dengan garam spa detox</li>
                          <li>#Tradisional massage</li>
                          <li>#Aroma Terapi</li>
                          <li>#Speaker music terapi relaksasi</li>
                          <li>#Oil Massage dan cream massage</li>
                          <li>#Gel Masker Mata</li>
                          <li>#Bantal Terapi Panas</li>
                          <li>#Totok Wajah di akhir sesi</li>
                        </ul>
                        <br />
                        <p>
                          Harga : <br />
                          60 Menit : 400K
                          <br />
                          90 Menit : 550K
                          <br />
                          120 Menit : 700k
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="right-list">
                    <ul>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Body massage &Totok Wajah</h4>
                          </a>
                          <p>
                            Kombinasi pijatan seluruh tubuh dengan totok wajah
                            yang bertujuan untuk merileksasikan seluruh tubuh
                            dan mengencangkan otot-otot wajah.
                            <br /> Harga : <br />
                            60 menit : 250k <br />
                            90 menit : 300k
                            <br /> 120 menit : 350k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/totokwajah.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Body massage & Scrub</h4>
                          </a>
                          <p>
                            Merileksasikan seluruh badan dengan pijatan dan di
                            akhiri dengan body scrub untuk mengangkat sel – sel
                            kulit mati dan mencerahkan kulit
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 450k
                            <br /> 120 menit : 600k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/scrub.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Body Massage & refleksi</h4>
                          </a>
                          <p>
                            Kombinasi pijatan seluruh badan dengan refleksi yang
                            berfokus pada titik tekanan pada kaki untuk
                            melancarkan sirkulasi darah dan meredakan stress.
                            <br /> Harga : <br />
                            60 menit : 250k <br />
                            90 menit : 300k
                            <br /> 120 menit : 350k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/refleksi.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="right-list">
                    <ul>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Lomi – lomi Massage</h4>
                          </a>
                          <p>
                            Jenis pijatan yang berasal dari Hawai yang bertujuan
                            melepaskan ketegangan, meningkatkan peredaran darah
                            dan penyembuhan yang mengintegrasikan tubuh, pikiran
                            dan jiwa.
                            <br /> Harga : <br />
                            60 menit : 250k <br />
                            90 menit : 300k
                            <br /> 120 menit : 350k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/lomi.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Couple Massage</h4>
                          </a>
                          <p>
                            Pijatan dua orang(Pasangan) untuk mendapatkan
                            pijatan secara bersamaan dalam satu ruangan yang
                            sama dan dilakukan dua terapis berbeda. Couple
                            massage cocok bagi pasangan yang ingin merayakan
                            moment special seperti ulang tahun atau hari jadi
                            pernikahan dan moment romantis lainnya.
                            <br /> Harga : <br />
                            60 menit : 500k <br />
                            90 menit : 600k
                            <br /> 120 menit : 700k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/couple.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Body massage & hair creambath</h4>
                          </a>
                          <p>
                            Perpaduan antara pijatan seluruh badan dan perawatan
                            rambut dengan krim untuk merileksasikan badan dan
                            sekaligus memberikan perawatan kulit kepala dan
                            rambut.
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 450k
                            <br /> 120 menit : 600k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/creambath.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="right-list">
                    <ul>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Deep Tissue massage & aroma terapi</h4>
                          </a>
                          <p>
                            Pijatan yang dilakukan dengan Teknik tekanan yang
                            kuat untuk menjangkau jaringan otot yang terdalam
                            bertujuan untuk meredakan ketegangan dan nyeri otot
                            kronis. Dipadukan dengan aroma terapi yang bertujuan
                            untuk menciptakan efek relaksasi dan memperbaiki
                            mood.
                            <br /> Harga : <br />
                            60 menit : 350k <br />
                            90 menit : 450k
                            <br /> 120 menit : 500k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/aroma.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Body massage & bekam</h4>
                          </a>
                          <p>
                            Kombinasi antara full body massage dan bekam kering.
                            Berfungsi untuk merileksasikan seluruh tubuh,
                            melancarkan peredaran darah, mengatasi sakit kepala
                            serta meningkatkan imunitas.
                            <br /> Harga : <br />
                            60 menit : 350k <br />
                            90 menit : 450k
                            <br /> 120 menit : 500k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/bekam.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Injury Sport Massage</h4>
                          </a>
                          <p>
                            Jenis pijatan yang khusus untuk merawat cidera atau
                            gangguan yang disebabkan oleh aktivitas olah raga
                            atau aktivitas fisik lainnya. Terapi ini pada
                            pengurangan rasa sakit nyeri peradangan dan
                            ketegangan otot yang terkait dengan cidera olahraga.
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 350k
                            <br /> 120 menit : 400k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/injury.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="right-list">
                    <ul>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Body Massage & Kerokan </h4>
                          </a>
                          <p>
                            Kombinasi antara full body massage dan kerokan.
                            Merileksasikan seluruh tubuh dan melancarkan aliran
                            darah serta mengobati rasa Lelah setelah
                            beraktivitas.
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 350k
                            <br /> 120 menit : 400k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/stone.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Thai Massage </h4>
                          </a>
                          <p>
                            Pijatan khas dari Thailand yang menggabungkan
                            Gerakan yoga, acupressure dan pijatan tradisional
                            untuk meningkatkan fleksibilitas meredakan nyeri dan
                            meningkatkan energi.
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 350k
                            <br /> 120 menit : 400k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/thai.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Hot Stone Massage</h4>
                          </a>
                          <p>
                            Terapi pijat yang menggunakan batu yang dipanaskan
                            pada titik tertentu bagian tubuh yang bermanfaat
                            untuk merelaksasikan otot, meredakan stress, dan
                            memperlancar sirkulasi darah
                            <br /> Harga : <br />
                            60 menit : 400k <br />
                            90 menit : 450k
                            <br /> 120 menit : 500k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/kerokan.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="right-list">
                    <ul>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Swedish Massage </h4>
                          </a>
                          <p>
                            Pijat yang menggunakan pijatan lembut namun kuat
                            pada otot-otot tubuh. Berfungsi untuk meningkatkan
                            peredaran limfatik, memperbaiki kualitas tidur dan
                            meningkatkan system ke kebalan tubuh.
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 350k
                            <br /> 120 menit : 400k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/swedish.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                      <li>
                        <div class="left-content align-self-center">
                          <a href="#">
                            <h4>Shiatsu massage </h4>
                          </a>
                          <p>
                            Jenis terapi pijatan Jepang yang menggunakan jari –
                            jari pada titik tertentu di tubuh untuk meningkatkan
                            aliran energi dan meredakan ketegangan.
                            <br /> Harga : <br />
                            60 menit : 300k <br />
                            90 menit : 350k
                            <br /> 120 menit : 400k
                          </p>
                        </div>
                        <div class="right-image">
                          <a href="#">
                            <img src="assets/images/shiatsu.jpg" alt="" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
