import { isMobile } from "react-device-detect";
export const About = () => {
    let img = "";
    if(isMobile) {
        img = "";
    }else{
      img = <img src="assets/images/left-banner.png" alt="Yaanz Spa" />
    }
    return (
        <div>
        <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="left-image wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
               {img}
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="services">
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img
                          src="assets/images/service-icon-01.png"
                          alt="reporting"
                        />
                      </div>
                      <div className="right-text">
                        <h4>Kemudahan Pemesanan</h4>
                        <p>
                          Dengan platform online kami, Anda dapat dengan mudah
                          memesan treatment spa tanpa harus meninggalkan
                          kenyamanan rumah atau tempat Anda berada. Cukup
                          kunjungi website kami, pilih treatment yang Anda
                          inginkan, dan atur jadwal sesuai keinginan Anda.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.7s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-02.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Kualitas Terapis Profesional</h4>
                        <p>
                          Kami hanya bekerja sama dengan terapis berkualifikasi
                          tinggi, bersertifikat, dan berpengalaman, yang akan
                          memberikan Anda pengalaman spa yang luar biasa dengan
                          teknik dan perawatan Spa premium.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img
                          src="assets/images/service-icon-01.png"
                          alt="reporting"
                        />
                      </div>
                      <div className="right-text">
                        <h4>Kenyamanan dan Privasi</h4>
                        <p>
                          Dengan layanan outcall yang kami sediakan, Anda dapat
                          menikmati treatment spa di rumah sendiri atau di
                          lokasi yang Anda pilih, tanpa harus repot pergi ke
                          spa. Ini memberikan privasi tambahan dan kenyamanan
                          tanpa harus berbagi ruang dengan orang lain.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.7s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-02.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Personalisasi</h4>
                        <p>
                          Kami memahami bahwa setiap pelanggan memiliki
                          kebutuhan dan preferensi yang berbeda. Oleh karena
                          itu, kami menawarkan layanan personalisasi yang
                          memungkinkan Anda untuk menyesuaikan treatment sesuai
                          dengan kebutuhan dan keinginan Anda.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
    )
}