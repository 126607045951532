import { Link } from "react-router-dom";
import style from "../css/map.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { isMobile } from "react-device-detect";

export const Home = () => {


  let img = "";
  let stat = "";
  let premium = "";
  let regular = "";
  if (isMobile) {
    stat = "portfolio-mobile";
    img = "";
    premium = "Premium Services";
    regular = "Regular Services";
  } else {
    stat = "portfolio";
    premium = "";
    regular = "";
    img = <img src="assets/images/left-banner.png" alt="Yaanz Spa" />;
  }
  
  return (
    <div>
      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div
                    className="left-content header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <h4>Selamat Datang di Yaanz Massage & Spa</h4>
                    <br />
                    <h5>
                      Yaan'z Massage & Spa adalah{" "}
                      <em>
                        layanan treatment outcall eksklusif yang berbasis online
                      </em>{" "}
                      Hadir untuk memenuhi kebutuhan, kenyamanan, dan kepuasan
                      Anda!
                    </h5>
                    <p>
                      <b>Komitmen pada Keamanan</b> : Kami mengutamakan keamanan
                      dan kesejahteraan pelanggan kami. Terapis kami dilengkapi
                      dengan peralatan Spa premium dan mengikuti protokol
                      kebersihan yang ketat untuk memastikan pengalaman spa yang
                      aman dan menyenangkan bagi Anda.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <video autoPlay muted loop width={"90%"}>
                      <source
                        src="assets/video/banner.mp4"
                        type="video/mp4"
                        alt="Yaanz Spa"
                      />
                    </video>
                    {/* <img src="assets/video/banner_2.gif" alt="Yaanz Spa" /> */}
                    {/* <video controls autoplay><source src="assets/video/banner.mp4" type="video/mp4" /></video>  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div
                className="left-image wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                {img}
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="services">
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img
                          src="assets/images/service-icon-01.png"
                          alt="reporting"
                        />
                      </div>
                      <div className="right-text">
                        <h4>Kemudahan Pemesanan</h4>
                        <p>
                          Dengan platform online kami, Anda dapat dengan mudah
                          memesan treatment spa tanpa harus meninggalkan
                          kenyamanan rumah atau tempat Anda berada. Cukup
                          kunjungi website kami, pilih treatment yang Anda
                          inginkan, dan atur jadwal sesuai keinginan Anda.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.7s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-02.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Kualitas Terapis Profesional</h4>
                        <p>
                          Kami hanya bekerja sama dengan terapis berkualifikasi
                          tinggi, bersertifikat, dan berpengalaman, yang akan
                          memberikan Anda pengalaman spa yang luar biasa dengan
                          teknik dan perawatan Spa premium.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img
                          src="assets/images/service-icon-01.png"
                          alt="reporting"
                        />
                      </div>
                      <div className="right-text">
                        <h4>Kenyamanan dan Privasi</h4>
                        <p>
                          Dengan layanan outcall yang kami sediakan, Anda dapat
                          menikmati treatment spa di rumah sendiri atau di
                          lokasi yang Anda pilih, tanpa harus repot pergi ke
                          spa. Ini memberikan privasi tambahan dan kenyamanan
                          tanpa harus berbagi ruang dengan orang lain.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.7s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-02.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Personalisasi</h4>
                        <p>
                          Kami memahami bahwa setiap pelanggan memiliki
                          kebutuhan dan preferensi yang berbeda. Oleh karena
                          itu, kami menawarkan layanan personalisasi yang
                          memungkinkan Anda untuk menyesuaikan treatment sesuai
                          dengan kebutuhan dan keinginan Anda.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id={stat} className="our-portfolio section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div
                className="section-heading  wow bounceIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h2>Our Treatments</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <Link to={"/premium"} href="#">
                <div
                  className="item wow bounceInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  {isMobile == false && (
                    <div className="hidden-content">
                      <h4>Premium Services</h4>
                      <p>
                        Home Spa Massage, Body massage & Scrub, Body Massage &
                        refleksi ,Body massage & Totok Wajah, Couple Massage
                        ,Body massage & hair creambath, Deep Tissue massage &
                        aroma terapi , Body massage & bekam, Injury Sport
                        Massage , Body Massage & Kerokan, Thai Massage, Hot
                        Stone Massage , Swedish Massage , Shiatsu Massage
                      </p>
                    </div>
                  )}
                  <div className="showed-content">
                    <h5 style={{ color: "#000" }}>{premium}</h5>
                    <img src="assets/images/premiumservice.jpg" alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Link to={"/regular"}>
                <div
                  className="item wow bounceInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  {isMobile == false && (
                    <div className="hidden-content">
                      <h4>Regular Services</h4>
                      <p>
                        Pijat Tradisonal , Massage Ibu Hamil , Totok Wajah ,
                        Refleksi , Hair Creambath , Scrub , Kerokan , Bekam{" "}
                      </p>
                    </div>
                  )}
                  <div className="showed-content">
                    <h5 style={{ color: "#000" }}>{regular}</h5>

                    <img src="assets/images/services.webp" alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id={stat} className="our-services section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="section-heading">
                <h2>Testimoni</h2>
              </div>
              <div className="row">
                <div
                  class="elfsight-app-f3615c83-69d0-4e1e-9405-ced9f6a01a7e"
                  data-elfsight-app-lazy
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact" className="contact-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div
                className="section-heading  wow bounceIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h2>Our Location</h2>
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center wow fadeInLeft"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <div className="section-heading">
                <h2>Found Me on Google Maps</h2>
                <p>
                  Jl. Taman Sari Madu No.12, kerobokan, Kec. Kuta Utara,
                  Kabupaten Badung, Bali 80117 <br /> Buka Lokasi{" "}
                  <a href="https://maps.app.goo.gl/KbUKe8jbVXSE3oXz9">
                    Klik Disini
                  </a>
                </p>
                <div className="phone-info">
                  <h4>
                    For any enquiry, Call Us:{" "}
                    <span>
                      <i className="fa fa-phone"></i>{" "}
                      <a href="#">0857-2378-5220</a>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <MapContainer
                center={[-8.671145717095218, 115.17572080553931]}
                zoom={13}
                scrollWheelZoom={true}
                id="mapid"
              >
                <TileLayer
                  attribution='&copy; <a href="#">Google Maps</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[-8.671145717095218, 115.17572080553931]}>
                  <Popup>
                    <b>{"Yaan'z Massage Outcall Home SPA service"}</b> <br />
                    {
                      "Jl. Taman Sari Madu No.12, kerobokan, Kec. Kuta Utara, Kabupaten Badung, Bali 80117"
                    }
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
