import { Link } from "react-router-dom";
export const Services = () => {
  return (
    <div>
      <div id="portfolio" className="our-portfolio section"  style={{paddingBottom:"100px"}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div
                className="section-heading  wow bounceIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h2>Our Treatments</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <Link to={"/premium"} href="#">
                <div
                  className="item wow bounceInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <div className="hidden-content">
                    <h4>Premium Services</h4>
                    <p>
                      Home Spa Massage, Body massage & Scrub, Body Massage &
                      refleksi ,Body massage & Totok Wajah, Couple Massage ,Body
                      massage & hair creambath, Deep Tissue massage & aroma
                      terapi , Body massage & bekam, Injury Sport Massage , Body
                      Massage & Kerokan, Thai Massage, Hot Stone Massage ,
                      Swedish Massage , Shiatsu Massage
                    </p>
                  </div>
                  <div className="showed-content">
                    <img src="assets/images/premiumservice.jpg" alt="" />
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Link to={"/regular"}>
                <div
                  className="item wow bounceInUp"
                  data-wow-duration="1s"
                  data-wow-delay="0.4s"
                >
                  <div className="hidden-content">
                    <h4>Regular Services</h4>
                    <p>
                      Pijat Tradisonal , Massage Ibu Hamil , Totok Wajah ,
                      Refleksi , Hair Creambath , Scrub , Kerokan , Bekam{" "}
                    </p>
                  </div>
                  <div className="showed-content">
                    <img src="assets/images/services.webp" alt="" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
