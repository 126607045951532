import { isMobile } from "react-device-detect";
export const Regular = () => {
  let stat = "";
  if (isMobile) {
    stat = "test-mobile";
  } else {
    stat = "test";
  }
    return (
      <div>
        <div class="container">
          <div class="row">
            <div id={stat}  class="our-blog section">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.25s"
                  >
                    <div class="section-heading" style={{textAlign:"left"}}>
                      <h2>
                        Check Out What Is <em>Regular Services</em>
                      </h2>
                      <p>
                        Service area : 
                        <div class="row">
                        <div class="col-lg-2">
                        <ul >
                          <li>* Denpasar</li>
                          <li>* Badung</li>
                         <li>* Ubud</li>
                        </ul>
                        </div>
                        <div class="col-lg-10">
                        <ul >
                          <li>* Tabanan</li>
                          <li>* Gianyar</li>
                         </ul>
                        </div>
                        </div>
                        <i>(Harga Regular free transportasi hanya di area Denpasar. Di luar area Denpasar akan dikenakan biaya transportasi. Biaya tergantung jarak (tanyakan admin).)</i>
                      </p>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.25s"
                  >
                    <div class="top-dec">
                      <img src="assets/images/blog-dec.png" alt="" />
                    </div>
                  </div>
                </div>
                <div class="row">
                
                  <div
                    class="col-lg-6 wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.25s"
                  >
                    <div class="right-list">
                      <ul>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Pijat Tradisonal</h4>
                            </a>
                            <p>
                            Pijat urut khas Indonesia yang bermanfaat untuk relaksasi, meningkatkan sirkulasi darah, meringankan nyeri otot dan sendi.


                              <br /> Harga : <br />
                              60 menit : 100k <br />
                              90 menit : 150k
                              <br /> 120 menit : 200k
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/tradisional.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Massage Ibu Hamil</h4>
                            </a>
                            <p>
                            Jenis terapi pijat yang khusus untuk Wanita hamil. Bertujuan untuk mengatasi kebutuhan dan kekhawatiran unik mereka selama kehamilan. Teknik pijat berfokus pada meredakan rasa tidak nyaman karena kehamilan, seperti nyeri punggung, kram kaki, pembengkakan dan kelelahan.
                              <br /> Harga : <br />
                              60 menit : 150k <br />
                              90 menit : 200k
                              <br /> 120 menit : 250k
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/ibuhamil.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Totok Wajah</h4>
                            </a>
                            <p>
                            Teknik pijatan yang berfokus pada titik refleksi di wajah, yang bermanfaat untuk memperbaiki keseimbangan energi di wajah.
                              <br /> Harga : <br />
                              60 menit : 100k <br />
                              90 menit : 150k
                              <br /> 
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/totokwajah.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <ul>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Kerokan</h4>
                            </a>
                            <p>
                            Praktik pengobatan tradisional Indonesia bertujuan untuk mengeluarkan angin, meredakan gejala pilek, dan membantu meningkatkan imun tubuh.
                              <br /> Harga : <br />
                              Per sesi : 100k <br />
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/kerokan.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                     
                        </ul>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 wow fadeInUp"
                    data-wow-duration="1s"
                    data-wow-delay="0.25s"
                  >
                    <div class="right-list">
                      <ul>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Refleksi</h4>
                            </a>
                            <p>
                            Bentuk terapi pijat yang berfokus pada titik refleksi di telapak kaki.
                              <br /> Harga : <br />
                              60 menit : 100k <br />
                              90 menit : 150k
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/refleksi.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Hair Creambath</h4>
                            </a>
                            <p>
                            Perawatan rambut dengan menggunakan krim rambut yang bermanfaat untuk merawat Kesehatan kulit kepala dan rambut
                              <br /> Harga : <br />
                              60 menit : 100k <br />
                              90 menit : 150k
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/creambath.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Scrub</h4>
                            </a>
                            <p>
                                Perawatan lulur pada kulit yang bermanfaat untuk membersihkan dan mencerahkan kulit serta mengangkat sel – sel kulit mati.
                              <br /> Harga : <br />
                             Per sesi : 100k
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/scrub.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Bekam</h4>
                            </a>
                            <p>
                            Teknik pengobatan alternative melalui vakum di atas kulit yang berfungsi untuk meningkatkan sirkulasi darah dan detox. Dapat mengobati sakit kepala dan nyeri punggung.
                              <br /> Harga : <br />
                              Per sesi : 100k <br />
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/bekam.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                        <li>
                          <div class="left-content align-self-center">
                            <a href="#">
                              <h4>Pedicure and manicure</h4>
                            </a>
                            <p>
                             Layanan perawatan kuku yang dilakukan untuk merawat dan mempercantik kuku.
                              <br /> Harga : <br />
                              Per sesi : 90k <br />
                            </p>
                          </div>
                          <div class="right-image">
                            <a href="#">
                              <img src="assets/images/pedicue.jpg" alt="" />
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  