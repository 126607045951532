import { Link, NavLink } from "react-router-dom";
import "./App.css";
import { Premium } from "./page/Premium";
import { Routes, Route } from "react-router-dom";
import { Regular } from "./page/Regular";
import { Home } from "./page/Home";
import { Services } from "./page/Services";
import { About } from "./page/About";
import { Found } from "./page/Found";
import { isMobile } from "react-device-detect";
import styles from "./css/navbar.css";

function App() {
  if (isMobile) {
 
    return (
      <div>
        <div className="App">
          <header
            className="header-area header-sticky wow slideInDown"
            data-wow-duration="0.75s"
            data-wow-delay="0s"
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <Link to="/" class="logo">
                    <img
                      src="assets/images/logo.png"
                      height="55%"
                      style={{ marginLeft: "-30px" }}
                    />
                  </Link>
                  <nav
                    style={{
                      color: "#fff",
                      backgroundColor: "#000",
                      width: "100%",
                      border: "1px solid #ccc",
                      marginTop: "10px",
                    }}
                  >
                    <ul className="oke">
                      <li>
                      <Link to="/">
                        <a href="#top">
                          Home
                        </a>
                        </Link>
                      </li>
                      <Link to="/about">
                      <li>
                        <a href="#about">About Us</a>
                      </li>
                      </Link>
                        <Link to="/services">
                      <li>
                        <a href="#portfolio">Services</a>
                      </li>
                      </Link>
                      <Link to="/found">
                      <li>
                        <a href="#contact">Found Us</a>
                      </li>
                      </Link>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </header>

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/premium" element={<Premium />} />
            <Route path="/regular" element={<Regular />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/found" element={<Found />} />
          </Routes>

          <footer>
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12 wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <p>
                    © Copyright 2024 Yaanz Massage and Spa. All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
          </footer>
          <Link
            to={
              "https://api.whatsapp.com/send?phone=6285723785220&text=Hallo%21%20Saya%20Tertarik%20Dengan%20Informasi%20di%20Website%20"
            }
            className="float"
            target="_blank"
            aria-label="WhatsApp PT Bintang Wistar Kencana"
          >
            <i className="fa fa-whatsapp my-float"></i>
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        {/* <div id="js-preloader" className="js-preloader">
          <div className="preloader-inner">
            <span className="dot"></span>
            <div className="dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div> */}

        <header
          className="header-area header-sticky wow slideInDown"
          data-wow-duration="0.75s"
          data-wow-delay="0s"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  <Link to="/" class="logo">
                    <img src="assets/images/logo.png" width={65} height={50} />
                  </Link>

                  <ul className="nav">
                  <Link to="/">
                    <li>
                      <a href="#top">
                        Home
                      </a>
                    </li>
                    </Link>
                    <Link to="/about">
                    <li>
                      <a href="#about">About Us</a>
                    </li>
                    </Link>
                    <Link to="/services">
                    <li>
                      <a href="#portfolio">Services</a>
                    </li>
                    </Link>
                    <Link to="/found">
                    <li>
                      <a href="#contact">Found Us</a>
                    </li>
                   </Link>
                    <Link
                      to={"https://www.instagram.com/yaanz_homemassagebali/"}
                      target="_blank"
                      className="btn-light nav-fill btn btn-square rounded-circle me-2"
                      aria-label="Instagram Yaanz Home Spa and Massage"
                    >
                      <i
                        className="fa fa-instagram text-primary"
                      
                      ></i>
                      
                    </Link>
                  
                      <Link
                      to={
                        "https://www.facebook.com/profile.php?id=61554844091153&mibextid=ZbWKwL"
                      }
                      target="_blank"
                      className="btn-light nav-fill btn btn-square rounded-circle me-2"
                      aria-label="Facebook Yaanz Home Spa and Massage"
                    >
                      <i className="fa fa-facebook text-primary"></i>
                    </Link>
                    
                  </ul>

                  {/* <Link className={`menu-trigger ${isActive ? "active" : ""}`} onClick={sayHello}>
                  <span>Menu</span>
                </Link> */}
                </nav>
              </div>
            </div>
          </div>
        </header>

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/premium" element={<Premium />} />
            <Route path="/regular" element={<Regular />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/found" element={<Found />} />
          </Routes>
        <footer>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.25s"
              >
                <p>
                  © Copyright 2024 Yaanz Massage and Spa. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <Link
          to={
            "https://api.whatsapp.com/send?phone=6285723785220&text=Hallo%21%20Saya%20Tertarik%20Dengan%20Informasi%20di%20Website%20"
          }
          className="float"
          target="_blank"
          aria-label="WhatsApp PT Bintang Wistar Kencana"
        >
          <i className="fa fa-whatsapp my-float"></i>
        </Link>
      </div>
    );
  }
}
export default App;
