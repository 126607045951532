import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
export const Found = () => {
  return (
    <div>
     <div id="contact" className="contact-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div
                className="section-heading  wow bounceIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h2>Our Location</h2>
              </div>
            </div>
            <div
              className="col-lg-6 align-self-center wow fadeInLeft"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <div className="section-heading">
                <h2>Found Me on Google Maps</h2>
                <p>
                  Jl. Taman Sari Madu No.12, kerobokan, Kec. Kuta Utara,
                  Kabupaten Badung, Bali 80117 <br /> Buka Lokasi{" "}
                  <a href="https://maps.app.goo.gl/KbUKe8jbVXSE3oXz9">
                    Klik Disini
                  </a>
                </p>
                <div className="phone-info">
                  <h4>
                    For any enquiry, Call Us:{" "}
                    <span>
                      <i className="fa fa-phone"></i> <a href="#">0857-2378-5220</a>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <MapContainer
                center={[-8.671145717095218, 115.17572080553931]}
                zoom={13}
                scrollWheelZoom={true}
                id="mapid"
              >
                <TileLayer
                  attribution='&copy; <a href="#">Google Maps</a>'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[-8.671145717095218, 115.17572080553931]}>
                  <Popup>
                    <b>{"Yaan'z Massage Outcall Home SPA service"}</b> <br />
                    {
                      "Jl. Taman Sari Madu No.12, kerobokan, Kec. Kuta Utara, Kabupaten Badung, Bali 80117"
                    }
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
